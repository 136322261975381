import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { SEO, PageHeader, Wrapper, RichText } from "components"
import { usePreviewData } from "utils"

function PrivacyPage() {
  const data = useStaticQuery(
    graphql`
      {
        prismicPrivacyPolicy {
          data {
            seo_title {
              text
            }
            seo_description {
              text
            }
            seo_image {
              url
              alt
            }
            hero_image {
              url
              alt

              fluid(maxWidth: 1600) {
                ...GatsbyPrismicImageFluid
              }
            }
            hero_video {
              url
            }
            hero_title {
              text
            }
            hero_subtitle {
              text
            }
            policy {
              html
            }
          }
        }
      }
    `
  )
  // Merge preview data with static query if possible
  const MERGED_DATA = usePreviewData(data)
  const { prismicPrivacyPolicy } = MERGED_DATA

  return (
    <>
      <SEO
        title={prismicPrivacyPolicy.data.seo_title}
        description={prismicPrivacyPolicy.data.seo_description}
        image={prismicPrivacyPolicy.data.seo_image}
      />
      <PageHeader
        image={prismicPrivacyPolicy.data.hero_image}
        video={prismicPrivacyPolicy.data.hero_video}
        title={prismicPrivacyPolicy.data.hero_title}
        subtitle={prismicPrivacyPolicy.data.hero_subtitle}
      />
      <Wrapper as="section" pt={[4, 5]}>
        <RichText content={prismicPrivacyPolicy.data.policy} maxWidth={640} />
      </Wrapper>
    </>
  )
}

export default PrivacyPage
